import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'
import { getCookie, setCookie } from '@/utils/support'
const _ = require('lodash')

axios.defaults.withCredentials = true // 允许携带cookie
// 创建axios实例
const service = axios.create({
	baseURL: process.env.BASE_API, // api的base_url
	timeout: 30000, // 请求超时时间
	withCredentials: true, // 允许携带cookie
})

// request拦截器
service.interceptors.request.use(
	config => {
		if (store.getters.token) {
			config.headers.Authorization = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
		}
		return config
	},
	error => {
		// Do something with request error
		Promise.reject(error)
	},
)

var MessageWithThrottle = _.throttle(function(messageInfo) {
	Message({
		message: messageInfo,
		type: 'error',
		duration: 5 * 1000,
	})
}, 3000);

// respone拦截器
service.interceptors.response.use(
	response => {
		/**
		 * code为非200是抛错 可结合自己业务进行修改
		 */

		const res = response.data

		if (
			response.headers['content-type'] === 'application/octet-stream' ||
			response.headers['content-type'] === 'application/vnd.ms-excel'
		) {
			// 提取文件名
			const filename = response.headers['content-disposition'].match(
				/filename=(.*)/,
			)[1]

			const blob = new Blob([response.data], { type: 'application/csv' })
			if (typeof window.navigator.msSaveBlob !== 'undefined') {
				// 兼容IE,window.navigator.msSaveBlob:以本地方式保存文件
				window.navigator.msSaveBlob(blob, decodeURI(filename))
			} else {
				const elink = document.createElement('a') // 创建一个<a>标签
				elink.style.display = 'none' // 隐藏标签
				elink.href = window.URL.createObjectURL(blob) // 配置href
				if (filename.indexOf('%') > -1) {
					elink.download = decodeURI(filename)
				} else {
					elink.download = filename
				}

				elink.click()
				URL.revokeObjectURL(elink.href) // 释放URL 对象
				if (document.body.contains(elink)) {
					document.body.removeChild(elink) // 移除<a>标签
				}
				return
			}
		}
		// res.code=200
		if (res.success || res.rpcSuccess) {
			return response.data
		}
		if (res.code !== 200) {
			// 401:未登录;
			if (res.code === 401) {
				store.dispatch('FedLogOut').then(() => {
						setInterval(() => {
							setCookie('invalidAccessMsg', res.message, 1)
							location.reload() // 为了重新实例化vue-router对象 避免bug
						}, 1000)
				})
			}
			MessageWithThrottle(res.message)
			return Promise.reject(response.data)
		} else {
			return response.data
		}
	},
	error => {
		MessageWithThrottle(res.message)
		return Promise.reject(error)
	},
)

export default service
