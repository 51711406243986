const getters = {
	sidebar: state => state.app.sidebar,
	device: state => state.app.device,
	token: state => state.user.token,
	avatar: state => state.user.avatar,
	brands: state => state.user.brands,
	brandId: state => state.user.brandId,
	name: state => state.user.name,
	brandName: state => state.user.brandName,
	shopName: state => state.user.shopName,
	roles: state => state.user.roles,
	addRouters: state => state.permission.addRouters,
	routers: state => state.permission.routers,
}
export default getters
