import { login, telLogin, logout, getInfo } from '@/api/login'
import { loadModle } from '@/api/product'
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
	state: {
		token: getToken(),
		name: '',
		brandName: '',
		shopName: '',
		avatar: '',
		roles: [],
		brands:[],
		brandId:'',
	},

	mutations: {
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_NAME: (state, name) => {
			state.name = name
		},
		SET_BRAND_NAME: (state, brandName) => {
			state.brandName = brandName
		},
		SET_SHOP_NAME: (state, shopName) => {
			state.shopName = shopName
		},
		SET_AVATAR: (state, avatar) => {
			state.avatar = avatar
		},
		SET_ROLES: (state, roles) => {
			state.roles = roles
		},
		SET_BRANDS: (state, brands) => {   
			state.brands = brands
		},

		SET_BRAND_ID: (state, brandId) => {
			state.brandId = brandId
		},
	},

	actions: {
		// 登录
		Login({ commit }, userInfo) {
			return new Promise((resolve, reject) => {
				if (userInfo.type == 1) {
					const username = userInfo.username.trim()
					login(username, userInfo.password)
					.then(response => {
						const data = response.data
						const tokenStr = data.tokenHead + data.token
						setToken(tokenStr)
						commit('SET_TOKEN', tokenStr)
						resolve()
					})
					.catch(error => {
						reject(error)
					})
				} else {
					telLogin({tel: userInfo.tel, type: 2, code: userInfo.code})
					.then(response => {
						const data = response.data
						const tokenStr = data.tokenHead + data.token
						setToken(tokenStr)
						commit('SET_TOKEN', tokenStr)
						resolve()
					})
					.catch(error => {
						reject(error)
					})
				}
			})
		},

		SmsCodeLogin({ commit }, registerInfo) {
			return new Promise((resolve, reject) => {
				login(username, userInfo.password)
					.then(response => {
						const data = response.data
						const tokenStr = data.tokenHead + data.token
						setToken(tokenStr)
						commit('SET_TOKEN', tokenStr)
						resolve()
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		// 抖音登录
		TikTokLogin({ commit }, loginInfo) {
			const tokenStr = loginInfo.tokenHead + loginInfo.token
			setToken(tokenStr)
			commit('SET_TOKEN', tokenStr)
		},

		FetchBrands({commit}) {
			loadModle({
				serviceId: 'starchain.brand.list.mapper',
				dubboParam: {},
			}).then(res => {
				if (res.success) {
					commit('SET_BRANDS', res.data.brandList)
				}
			})
		},
		UpdateBrandId({commit}, brandId) {
			commit('SET_BRAND_ID', brandId)
		},
		// 获取用户信息
		GetInfo({ commit, state }) {
			return new Promise((resolve, reject) => {
				getInfo()
					.then(response => {
						const data = response.data
						if (data.roles && data.roles.length > 0) {
							// 验证返回的roles是否是一个非空数组
							commit('SET_ROLES', data.roles)
						} else {
							reject('getInfo: roles must be a non-null array !')
						}
						commit('SET_NAME', data.username)
						commit('SET_BRAND_NAME', data.brandName)
						commit('SET_SHOP_NAME', data.shopName)
						commit('SET_AVATAR', data.icon)
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		// 登出
		LogOut({ commit, state }) {
			return new Promise((resolve, reject) => {
				logout(state.token)
					.then(() => {
						commit('SET_TOKEN', '')
						commit('SET_ROLES', [])
						removeToken()
						resolve()
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		// 前端 登出
		FedLogOut({ commit }) {
			return new Promise(resolve => {
				commit('SET_TOKEN', '')
				removeToken()
				resolve()
			})
		},
	},
}

export default user
