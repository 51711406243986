import router from './router'
import store from './store'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth' // 验权
import { isStaffHome } from '@/utils/platform'

const whiteList = ['/login', '/ttlogin', '/cslogin', '/foodhome', '/childhome', '/qwhome', '/qiuweihome'] // 不重定向白名单
router.beforeEach((to, from, next) => {
	NProgress.start()
	// 验证用户是否登陆并获取token
	if (getToken()) {
		// 如果已经登陆重定向到主页
		if (to.path.indexOf('login') > -1) {
			next({ path: '/' })
			NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
		} else {
			if (store.getters.roles.length === 0) {
				store.dispatch('FetchBrands')
				store
					.dispatch('GetInfo')
					.then(res => {
						// 拉取用户信息
						const menus = res.data.menus
						const username = res.data.username
						const roles = res.data.roles
						store
							.dispatch('GenerateRoutes', {
								menus,
								username,
								roles,
							})
							.then(() => {
								// 生成可访问的路由表
								router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
								if (isStaffHome()) {
									next({ path: 'ttvipBrand'})
								} else {
									next({ ...to, replace: true })
								}
								
							})
					})
					.catch(err => {
						store.dispatch('FedLogOut').then(() => {
							Message.error(
								err ||
									'Verification failed, please login again',
							)
							next({ path: '/' })
						})
					})
			} else {
				next()
			}
		}
	} else {
		if (whiteList.indexOf(to.path) !== -1) {
			// 去登陆白名单
			next()
		} else {
			// 没有访问权限的其他页面被重定向到登录页面
			next('/cslogin')
			NProgress.done()
		}
	}
})

router.afterEach(() => {
	NProgress.done() // 结束Progress
})
