<template>
  <div class="app-wrapper" :class="classObj">
    <sidebar id="sidebar" class="sidebar-container" @showAddBrandDialog="showAddBrandDialog"></sidebar>
    <nav-header class="navheader">
    </nav-header>
    <div class="main-container">
      <navbar></navbar>
      <app-main></app-main>
    </div>
    <brand-edit-dialog ref="editDialog" :dialog-visible="visible" @cancelDialog="cancelDialog"></brand-edit-dialog>
  </div>
</template>

<script>
import { Navbar, NavHeader, Sidebar, AppMain } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import BrandEditDialog from '@/components/BrandEditDialog'

export default {
  name: 'Layout',
  components: {
    Navbar,
    NavHeader,
    Sidebar,
    AppMain,
    BrandEditDialog,
  },
  mixins: [ResizeMixin],
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile',
      }
    },
  },
  methods: {
    showAddBrandDialog() {
      this.visible = true
      this.$refs.editDialog.prepareCreate()
    },
    cancelDialog() {
      this.visible = false
    }
  },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import 'src/styles/mixin.scss';
.app-wrapper {
	@include clearfix;
	position: relative;
	// height: 100%;
	// width: 100%;

	height: calc(100vh);
	// min-width: 1440px;
	// max-width: 1440px;
	min-width: 1400px;
	max-width: 1400px;
	margin: auto;
}

@media screen and (min-width: 1490px) {
	.app-wrapper {
		@include clearfix;
		position: relative;
		// height: 100%;
		// width: 100%;

		height: calc(100vh);
		min-width: 1440px;
		max-width: 1440px;
		// min-width: 1400px;
		// max-width: 1400px;
		margin: auto;
	}

	.navheader {
		width: 1440px;
	}
}
@media screen and (max-width: 1490px) {
	.app-wrapper {
		@include clearfix;
		position: relative;
		// height: 100%;
		// width: 100%;

		height: calc(100vh);
		// min-width: 1440px;
		// max-width: 1440px;
		min-width: 1400px;
		max-width: 1400px;
		margin: auto;
	}

	.navheader {
		width: 1400px;
	}
}

.el-divider--horizontal {
	margin: 0;
}

.navheader {
	height: 80px;
	position: fixed;
	background: white;
	display: flex;
	justify-content: space-between;
	z-index: 1010;
}
</style>
