<template>
  <scroll-bar>
    <div class="sidebar-logo" :style="{height: '80px'}">
    </div>
    <el-menu mode="vertical" :show-timeout="200" :default-active="$route.path" :collapse="isCollapse" background-color="white" text-color="#1D1D2D" active-text-color="#2650F5">
      <sidebar-item :routes="routes"></sidebar-item>
    </el-menu>
  </scroll-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'
import ScrollBar from '@/components/ScrollBar'
import { setCookie, getCookie } from '@/utils/support'
import router from '@/router'

export default {
  components: { SidebarItem, ScrollBar },
  data() {
    return {
      brandId: '',
      selectedImageUrl: '',
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'routers', 'brands', 'roles']),
    routes() {
      // return this.$router.options.routes
      return this.routers
    },
    isCollapse() {
      return !this.sidebar.opened
    },
    isVip() {
      return this.roles.length == 1 && (this.roles[0] == '14' || this.roles[0] == '15')
    },
  },
  watch: {
    'brandId': function (newVal, oldVal) {
      if ((newVal == -1 && oldVal > 0) || (newVal > 0 && oldVal == -1)) {
        this.updateRouters()
        this.$router.push({ path: '/ttbrand/brandOverview' })
      }
    },
  },
  created() {
    let brandInfo = getCookie('selected_brand_info')
    if (brandInfo) {
      brandInfo = JSON.parse(brandInfo)
      this.brandId = brandInfo.id
      this.selectedImageUrl = brandInfo.douyinImageUrl
    }
  },
  methods: {
    changeBrand(e) {
      this.brands.forEach(item => {
        if (e == -2) {
          this.$emit('showAddBrandDialog')
          this.brandId = parseInt(getCookie('selected_brand_id'))
        } else if (item.id == e) {
          this.selectedImageUrl = item.douyinImageUrl
          setCookie('selected_brand_info', JSON.stringify(item), 15)
          setCookie('selected_brand_id', item.id, 15)
          this.$store.dispatch('UpdateBrandId', item.id)
        }
      })
    },
    updateRouters() {
      this.$store
        .dispatch('GetInfo')
        .then(res => {
          // 拉取用户信息
          const menus = res.data.menus
          const username = res.data.username
          const roles = res.data.roles
          this.$store
            .dispatch('GenerateRoutes', {
              menus,
              username,
              roles,
            })
            .then(() => {
              // 生成可访问的路由表
              router.addRoutes(this.$store.getters.addRouters) // 动态添加可访问路由表
            })
        })
    },
  }
}
</script>

<style >
.eloption .el-select-dropdown__wrap {
	max-height: 400px !important;
}
</style>

<style lang="less" scoped>
.sidebar-container /deep/ div ul div a li:hover {
	background-color: #f5f6fa !important;
	border-radius: 8px;
}

.brand-select-container {
	margin-bottom: 12px;
}

.sidebar-container
	/deep/
	div
	ul
	div
	a.router-link-exact-active.router-link-active
	li {
	background-color: #f5f6fa !important;
	border-radius: 8px;
}

#sidebar /deep/ div ul div li.el-submenu.is-active.is-opened div {
	background: #f5f6fa !important;
	border-radius: 8px 8px 0px 0px;
}

#sidebar /deep/ div ul div li.el-submenu.is-active.is-opened ul a li {
	background: #f5f6fa !important;
}

#sidebar
	/deep/
	div
	ul
	div
	li.el-submenu.is-active.is-opened
	ul
	a:last-child
	li {
	border-radius: 0px 0px 8px 8px;
}

/deep/
	li.el-submenu.is-active.is-opened
	ul
	a.router-link-exact-active.router-link-active
	li::before {
	content: ''; /* 在标题前面插入文字 并设置样式 */
	width: 3px;
	height: 8px;
	position: absolute;
	top: 9px;
	left: 32px;
	border-radius: 1.5px;
	background: #2650f5;
}

/deep/ .el-submenu__icon-arrow {
	right: 10px;
}

/deep/ .el-menu-item {
	padding-left: 8px !important;
}

/deep/ .el-submenu__title {
	padding-left: 8px !important;
	padding-right: 0 !important;
}

/deep/ .el-submenu .el-menu-item {
	padding-left: 41px !important;
}

.brand-container {
	display: flex;
	align-items: center;
}

.brand-icon {
	width: 32px;
	height: 32px;
	border-radius: 16px;
	margin-right: 8px;
}

.el-brand-icon {
	width: 24px;
	height: 24px;
	border-radius: 12px;
	margin-right: 8px;
}

.selected-brand-icon {
	margin-top: 3px;
}

/deep/ .el-input--prefix .el-input__inner {
	padding-left: 45px;
}

// /deep/ .el-input--suffix .el-input__inner {
//     padding-right: 10px;
// }

/deep/ .el-select .el-input input:hover {
	background: #f5f6fa;
	border-radius: 20px;
}

/deep/ .el-input input {
	background: white;
	border-radius: 8px;
}

/deep/ .el-input__inner {
	border: none;
}
</style>
