<template>
  <el-menu class="navbar" :style="{ height: navbarHeight }" mode="horizontal">
    <div v-if="showTitle()" class="title-container">
      <label class="title-label">{{ title }}</label>
    </div>
  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      animation: false,
      editDialogVisible: false,
      title: '',
      pwdInfo: {
        origin: '',
        current: '',
        confirm: '',
      },
      isVipWelcome: false,
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'name']),
    navbarHeight: function () {
      if (this.title == '品牌首页' || this.title == '支付确认') {
        return '80px'
      }
      return this.title === '首页' ? '50px' : '144px'
    },
    avatarContainerHeight: function () {
      return '80px'
    },
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    },
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('ToggleSideBar')
    },
    getBreadcrumb() {
      const matched = this.$route.matched.filter(item => item.name)
      this.title = matched[matched.length - 1].meta.title
      this.isVipWelcome = this.title === '品牌首页'
    },
    showTitle() {
      let titles = ['首页', '品牌首页', '支付确认']
      return titles.indexOf(this.title) < 0
    }
  },
}
</script>

<style lang="less" scoped>
// <style lang="scss" scoped>
@import '/src/base.less';

.el-divider--horizontal {
	margin: 0;
}

.qrcode {
	width: 300px;
	height: 300px;
}

.navbar {
	border-radius: 0px !important;

	.title-label {
		display: inline-block;
		height: 60px;
		line-height: 60px;
		position: absolute;
		left: 40px;
		// color: rgba(0, 0, 0, 0.85);
		color: #1d1d2d;
		font-weight: bold;
		font-size: 20px;
	}

	.screenfull {
		position: absolute;
		right: 90px;
		top: 16px;
		color: red;
	}

	.title-container {
		width: 100%;
		position: absolute;
		top: 80px;
		height: 64px;

		background-color: @base-app-title-container-bg;
	}

	.customer-service {
		margin: 20px 35px 20px 20px;
		display: flex;
		align-items: center;
		.customer-label {
			margin-left: 8px;
			font-family: PingFangSC-Regular;
			font-size: 14px;
			color: #646474;
			line-height: 20px;
		}
	}

	.animation {
		animation: loading 0.75s linear infinite forwards;
	}

	@keyframes loading {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	.avatar-container {
		height: 64px;
		display: inline-block;
		position: fixed;
		margin-left: 915px;

		.avatar-wrapper {
			cursor: pointer;
			position: relative;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			.user-avatar {
				width: 24px;
				height: 24px;
				border-radius: 12px;
			}

			.user-name {
				padding: 0 3px 0 8px;
			}

			.el-icon-caret-bottom {
				position: absolute;
				right: -15px;
				font-size: 12px;
			}
		}
	}
}
</style>
