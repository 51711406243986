<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    :before-close="handleClose"
    center
    width="40%"
  >
    <el-card shadow="never">
      <label class="edit-dialog-label">品牌信息</label>
      <el-form
        ref="updateFormRef"
        :model="updateForm"
        :rules="addFormRules"
      >
        <el-form-item
          label="品牌名称:"
          :label-width="formLabelWidth"
          prop="brandName"
          required
        >
          <el-input
            v-model="updateForm.brandName"
            autocomplete="off"
            style="width:300px;"
            @input="$forceUpdate()"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="抖音号名称:"
          :label-width="formLabelWidth"
          prop="douyinName"
          required
        >
          <el-input
            v-model="updateForm.douyinName"
            autocomplete="off"
            style="width:300px;"
            @input="$forceUpdate()"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="抖音号头像:"
          :label-width="formLabelWidth"
        >
          <el-upload
            :file-list="douyinImgList"
            action="https://api-tt.beisheng.com/japi/outer/api?starchain.operate.image.upload"
            list-type="picture-card"
            :before-upload="beforeUpload.bind(this, 'douyin')"
            :on-success="
              (rep, file, fileList) =>
                handleSuccess(rep, file, fileList, 1)
            "
            :on-remove="
              (file, fileList) =>
                handleRemove(file, fileList, 1)
            "
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="抖店名称:"
          :label-width="formLabelWidth"
          prop="doudianName"
          required
        >
          <el-input
            v-model="updateForm.doudianName"
            style="width:300px;"
            @input="$forceUpdate()"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="品牌佣金率:"
          :label-width="formLabelWidth"
          prop="cmsRate"
          required
        >
          <el-input
            v-model="updateForm.cmsRate"
            style="width:300px;"
            @input="$forceUpdate()"
          ></el-input>%
        </el-form-item>
        <el-form-item
          label="运营状态:"
          :label-width="formLabelWidth"
          required
        >
          <el-select
            v-model="updateForm.status"
            placeholder="选择运营状态"
            style="width:300px;"
          >
            <el-option
              v-for="item in statusMapper"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="入驻时间:"
          :label-width="formLabelWidth"
          required
        >
          <el-date-picker
            v-model="updateForm.enterDate"
            style="width:300px;"
            align="right"
            type="date"
            placeholder="选择入住日期"
            @change="$forceUpdate()"
          ></el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 16px;" shadow="never">
      <label class="edit-dialog-label">品牌目标</label>
      <el-form :model="updateForm" :rules="addFormRules">
        <el-form-item
          label="目标月份:"
          :label-width="formLabelWidth"
          required
        >
          <el-select
            v-model="updateForm.targetMonth"
            placeholder="选择运营状态"
            style="width:300px;"
          >
            <el-option
              v-for="item in months"
              :key="item.value"
              :label="item.desc"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="目标GMV（w）:"
          :label-width="formLabelWidth"
          prop="targetGMV"
        >
          <el-input
            v-model="updateForm.targetGmv"
            style="width:300px;"
            @input="$forceUpdate()"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">
        取 消
      </el-button>
      <el-button type="primary" @click="updateBrancdInfo()">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

import { loadModle } from '@/api/product'
import { getToken } from '@/utils/auth'
import axios from 'axios'
import { getCookie } from '@/utils/support'
import moment from 'moment'

export default {
  name: 'BrandEditDialog',
  props: {
    dialogTitle: {
      type: String,
      default: '新增品牌',
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: this.dialogVisible,
      updateForm: {
      },
      statusMapper: {},
      months: [],
      douyinImgList: [],
      addFormRules: {
        brandName: [
          {
            required: true,
            message: '请输入品牌名',
            trigger: 'blur',
          },
        ],
        douyinName: [
          {
            required: true,
            message: '请输入抖音号名称',
            trigger: 'blur',
          },
        ],
        doudianName: [
          {
            required: true,
            message: '请选择抖店名称',
            trigger: 'blur',
          },
        ],
        cmsRate: [
          {
            required: true,
            message: '请设置佣金率',
            trigger: 'blur',
          },
        ],
        roles: [],
      },
      formLabelWidth: '30%',
      items: [],
      optionAvailable: true,
      searchForm: {
				brandName: null,
				douyinName: null,
        targetMonth: ''
			},
    }
  },
  computed: {
    timeDefault() {
      let date = new Date()
      let month = date.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      return date.getFullYear()
        + "-"
        + month
    }
  },
  watch: {
    'dialogVisible': function(newVal, oldVal) {
      this.visible = newVal
    }
  },
  created() {
      this.initMonth()
  },
  methods: {
    
    initMonth() {
      this.searchForm.targetMonth = this.timeDefault
			for (let i = 0; i < 12; i++) {
				this.months.push({
					desc: i + 1 + '月',
					value: i + 1,
				})
			}
		},
    handleClose(done) {
      this.$emit('cancelDialog')
    },
    beforeUpload(type, file) {
      const imgSize = Number(file.size / 1024 / 1024)
      if (imgSize > 1) {
        this.$message({
          title: '文件超限',
          message: '文件大小不能超过1MB，请重新上传。',
          type: 'warning',
        })
        return false
      }
      const timeStamp = new Date() - 0
      const copyFile = new File([file], `${timeStamp}_${file.name}`)
      this.datailUploadFile(copyFile, type)
      return false
    },
    datailUploadFile(copyFile, type) {
      const data = new FormData()
      data.append('file', copyFile)
      axios.post(
        'https://api-tt.beisheng.com/japi/outer/api?starchain.operate.image.upload',
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: getToken(),
          },
        },
      ).then(res => {
        if (res.status == 200) {
          if (type == 'douyin') {
            this.douyinImgList = [
              {
                url: res.data.data,
                status: 'finished',
              },
            ]
            this.updateForm.douyinImageUrl = res.data.data
          }
        }
      })
    },
    cancel() {
      this.$emit('cancelDialog')
    },
    async prepareCreate() {
      await this.initialize()
			this.brandId = null
			this.updateForm = {
				status: '1',
				targetGmv: '',
				targetMonth: '',
			}

			this.douyinImgList = []
    },
    async showDialog() {
      await this.initialize()
      let data = this.items[0]
      this.dialogFormVisible = true
      this.brandId = data != null && data.id != null ? data.id : null
      this.updateForm = {
        status: '1',
        targetGmv: '',
        targetMonth: '',
      }

      this.douyinImgList = []

      if (data) {
        this.updateForm.brandName = data.brandName
        this.updateForm.targetGmv = data.targetGMV
        this.updateForm.targetMonth = data.targetMonth
        if (!this.updateForm.targetMonth) {
          const date = new Date()
          this.updateForm.targetMonth = date.getMonth() + 1
        }

        if (data.douyinImageUrl) {
          this.douyinImgList = [
            {
              url: data.douyinImageUrl,
              status: 'finished',
            },
          ]
          this.updateForm.douyinImageUrl = data.douyinImageUrl
        }

        this.updateForm.douyinName = data.douyinName
        this.updateForm.doudianName = data.doudianName
        this.updateForm.status = data.status + ''
        this.updateForm.enterDate = new Date(data.enterTime * 1000)
        this.updateForm.cmsRate = data.cmsRate

      }
    },
    async initialize() {
      const that = this
      this.loading = true

      const params = Object.assign({}, this.searchForm)

      const res = await loadModle({
        serviceId: 'starchain.douyin.brand.list',
        dubboParam: params,
      })

      this.totalCount = res.data.totalCount

      this.statusShowMapper = this.getStatusMapper(res.data.statusMapper)
      this.statusMapper = res.data.statusMapper
      this.optionAvailable = res.data.optionAvailable

      if (!res.data.calcResultTO) {
        res.data.calcResultTO = {
          totalGMV : 0,
          totalBuyFlowFee: 0,
          totalROI : '0',
          totalLiveTime : 0,
        }
      }

      let brandId = getCookie('selected_brand_id')

      this.items = res.data.itemList.filter(item => {
        return item.id == parseInt(brandId)
      })


      this.items.forEach(v => {
        v.enterTimeDesc = that.formatDate(v.enterTime * 1000)
        v.gmtFinishOpeateDesc = that.formatDate(v.gmtFinishOpeate * 1000)

        if (!v.douyinImageUrl) {
          v.douyinImageUrl = 'http://h0.beicdn.com/open202151/a50e308860fe4d12_56x56.jpg'
        }


        if (!v.targetMonth) {
          let components = that.searchForm.targetMonth.split('-')
          v.targetMonth = parseInt(components[1])
          v.targetGMV = 0
        }

        v.status = v.status + ''
        v.cmsRate = v.cmsRate * 100
        // v.operator = that.getValueList(v.operatorList);

        if (v.gmvProcessPercent) {
          v.gmvProcessPercent = v.gmvProcessPercent.replace('%', '')  
        } else {
          v.gmvProcessPercent = '0'
        }
        
        v.gmvProcessNum = parseFloat(v.gmvProcessPercent)

        const now = new Date()
        const day = now.getDate()

        const month = now.getMonth() + 1
        if (month != v.targetMonth) {
          v.timeProcess = 100
        } else {
          const d = new Date(now.getFullYear(), v.targetMonth, 0)
          const days = d.getDate()
          v.timeProcess = Number((day * 100 * 1.0) / days).toFixed(0)
          v.timeProcess = parseFloat(v.timeProcess)
        }
      })
    },
    getStatusMapper(statusList) {
      const map = {}

      statusList.forEach(v => {
        map[v.value] = v.name
      })
      return map
    },
    formatDate(value) {
      return moment(value).format('YYYY-MM-DD')
    },
    getName(list) {
			let name = ''

			if (list == null || list.length == 0) {
				return name
			}

			list.forEach(v => {
				name += ' ' + v.name + ' ,'
			})

			return name.slice(0, name.length - 1)
		},
    getValue(list) {
			const values = null

			if (list == null || list.length == 0) {
				return values
			}

			return list[0].value + ''
		},
    getValueList(list) {
			const values = []

			if (list == null || list.length == 0) {
				return values
			}

			list.forEach(v => {
				values.push(v.value + '')
			})

			return values
		},
    async updateBrancdInfo() {
			const tempParam = this.updateForm
			const params = {}

			this.$refs.updateFormRef.validate(valid => {
				if (valid) {
					if (tempParam.enterDate == null) {
						this.$message.error('请选择入驻时间!')
						return
					}
					
					params.id = this.brandId
					params.brandName = tempParam.brandName
					params.douyinName = tempParam.douyinName
					params.doudianName = tempParam.doudianName
					params.status = tempParam.status
					params.enterTime = tempParam.enterDate.getTime() / 1000

					params.targetGmv = tempParam.targetGmv
					params.targetMonth = tempParam.targetMonth
					params.douyinImageUrl = tempParam.douyinImageUrl

          params.cmsRate = tempParam.cmsRate / 100 + ''
					this.updateBrand(params)
				}
			})
		},
    async updateBrand(params) {
			const res = await loadModle({
				serviceId: 'starchain.douyin.brand.save',
				dubboParam: params,
			})

			if (res && res.success) {
				this.$nextTick(() => {
					this.$refs.updateFormRef.resetFields()
          this.$message({
					type: 'success',
					message: '更新成功',
				})
        this.$emit('cancelDialog')
				})
			} else {
				this.$message({
					type: 'error',
					message: (res && res.message) || '系统异常',
				})
			}
		},
    handleSuccess(res, file, fileList, index) {
			// index 1 为抖音图片，2 为抖店图片
			if (index == 1) {
				this.updateForm.douyinImageUrl = res.data

			}
		},
		handleRemove(file, fileList, index) {
			if (index == 1) {
				this.updateForm.douyinImageUrl = ''
			}
		},
  },
}
  
</script>

<style lang="less" scoped>

/deep/ .el-upload--picture-card {
    width: 56px;
    height: 56px;
    line-height: 64px;
    background: #F9F9F9;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 56px;
    height: 56px;
}

</style>