import request from '@/utils/request'
export function loadModle(data) {
	return request({
		method: 'post',
		url:
			'https://api-tt.beisheng.com/japi/outer/api?rebate.operate.generic.service',
		data: data,
	})
}

export function downloadPic(offerId) {
	return request({
		url: `https://api-tt.beisheng.com/japi/outer/api?offerId=${offerId}&starchain.1688.item.material.download`,
		method: 'get',
		responseType: 'blob',
	})
}
export function uploadPic(data) {
	return request({
		method: 'post',
		url: `https://api-tt.beisheng.com/japi/outer/api?starchain.operate.similar.image&page=2`,
		data: data,
	})
}
export function updateDeleteStatus(params) {
	return request({
		url: '/product/update/deleteStatus',
		method: 'post',
		params: params,
	})
}

export function updateNewStatus(params) {
	return request({
		url: '/product/update/newStatus',
		method: 'post',
		params: params,
	})
}

export function updateRecommendStatus(params) {
	return request({
		url: '/product/update/recommendStatus',
		method: 'post',
		params: params,
	})
}

export function updatePublishStatus(params) {
	return request({
		url: '/product/update/publishStatus',
		method: 'post',
		params: params,
	})
}

export function createProduct(data) {
	return request({
		url: '/product/create',
		method: 'post',
		data: data,
	})
}

export function updateProduct(id, data) {
	return request({
		url: '/product/update/' + id,
		method: 'post',
		data: data,
	})
}

export function getProduct(id) {
	return request({
		url: '/product/updateInfo/' + id,
		method: 'get',
	})
}
