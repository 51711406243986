import { asyncRouterMap, constantRouterMap } from '@/router/index'
import { getCookie } from '@/utils/support'

// 判断是否有权限访问该菜单

function totalMenusControl(menus, route) {
	if (route.hidden) {
		return false
	}
	if (route.name) {
		const currMenu = getMenu(route.name, menus)
		if (currMenu != null) {
			// 设置菜单的标题、图标和可见性

			if (currMenu.alias != null && currMenu.alias !== '') {
				route.meta.title = currMenu.alias
			}
			if (currMenu.icon != null && currMenu.title !== '') {
				route.meta.icon = currMenu.icon
			}
			if (currMenu.hidden != null) {
				route.hidden = currMenu.hidden
			}
			if (currMenu.sort != null && currMenu.sort !== '') {
				route.sort = currMenu.sort
			}
			return true
		} else {
			route.sort = 0
			if (route.hidden !== undefined && route.hidden === true) {
				route.sort = -1
				return true
			} else {
				return false
			}
		}
	} else {
		return false
	}
}

// 根据路由名称获取菜单
function getMenu(name, menus) {
	for (let i = 0; i < menus.length; i++) {
		const menu = menus[i]
		if (name === menu.name) {
			return menu
		}
	}
	return null
}

// 对菜单进行排序
function sortRouters(accessedRouters) {
	for (let i = 0; i < accessedRouters.length; i++) {
		const router = accessedRouters[i]
		if (router.children && router.children.length > 0) {
			router.children.sort(compare('weight'), false)
		}
	}
	accessedRouters.sort(compare('weight'), true)
}

// 降序比较函数
function compare(p, flag) {
	return function(m, n) {
		const a = m[p]
		const b = n[p]
		if (!flag) {
			return a - b
		} else {
			return b - a
		}
	}
}

const permission = {
	state: {
		routers: constantRouterMap,
		addRouters: [],
	},
	mutations: {
		SET_ROUTERS: (state, routers) => {
			state.addRouters = routers
			state.routers = constantRouterMap.concat(routers)
		},
	},
	actions: {
		GenerateRoutes({ commit }, data) {
			return new Promise(resolve => {
				const { menus } = data
				let accessedRouters = []

				
				let isAllAccountSelected = false
				let brandId = getCookie('selected_brand_id')
				if (brandId === '-1') {
					isAllAccountSelected = true
				}
				accessedRouters = asyncRouterMap.filter(v => {
					if (v.name === 'home') {
						return true
					}

					if (isAllAccountSelected) {
						return v.name === 'ttbrand'
					}
					return totalMenusControl(menus, v)
				})

				// 处理二级目录
				accessedRouters.forEach(i => {
					if (i.name && i.name != 'home') {
						const currMenu = getMenu(i.name, menus)
						if (!currMenu) {
							return
						}
						i.id = currMenu.id

						if (i.children && i.children.length > 0) {
							if (currMenu) {
								i.weight = currMenu.weight
							}
							if (currMenu.children) {
								i.children = i.children.filter(child => {
									const backendChild = getMenu(
										child.name,
										currMenu.children,
									)
									if (backendChild) {
										child.weight = backendChild.weight
										child.id = backendChild.id
										child.meta.title = backendChild.alias
									}

									if (!backendChild || backendChild.hidden) {
										return false
									} else {
										return true
									}
								})

								if (i.children.length == 1) {
									i.children[0].meta.icon = i.meta.icon
								}

							} else {
								const child = getMenu(i.children[0].name, menus)
								i.children[0].weight = 0
								if (child) {
									i.children[0].id = child.id
								}
							}
						}
					}
				})

				// 对菜单进行排序
				sortRouters(accessedRouters)
				commit('SET_ROUTERS', accessedRouters)
				resolve()
			})
		},
	},
}

export default permission
