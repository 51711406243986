<template>
  <div
    ref="scrollContainer"
    class="scroll-container"
    @wheel.prevent="handleScroll"
  >
    <div
      ref="scrollWrapper"
      class="scroll-wrapper"
      :style="{ top: top + 'px' }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
const delta = 15

export default {
	name: 'ScrollBar',
	data() {
		return {
			top: 0,
		}
	},
	methods: {
		handleScroll(e) {
			const eventDelta = e.wheelDelta || -e.deltaY * 3
			const $container = this.$refs.scrollContainer
			const $containerHeight = $container.offsetHeight
			const $wrapper = this.$refs.scrollWrapper
			const $wrapperHeight = $wrapper.offsetHeight
			if (eventDelta > 0) {
				this.top = Math.min(0, this.top + eventDelta)
			} else {
				if ($containerHeight - delta < $wrapperHeight) {
					if (
						this.top < -($wrapperHeight - $containerHeight + delta)
					) {
						this.top = this.top
					} else {
						this.top = Math.max(
							this.top + eventDelta,
							$containerHeight - $wrapperHeight - delta,
						)
					}
				} else {
					this.top = 0
				}
			}
		},
	},
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import '../../styles/variables.scss';

.scroll-container {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: white;
	.scroll-wrapper {
		position: absolute;
		width: 100% !important;
		// padding: 0 8px 0 16px;
	}
}
</style>
