import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '../views/layout/Layout'
/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
 **/

// 处理刚登录成功后报from “/login“ to “/home“ via a navigation guard的bug
  const originalPush = Router.prototype.push
  Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
  }
export const constantRouterMap = [
  { path: '/login', component: () => import('@/views/login/index'), hidden: true },
  { path: '/ttlogin', component: () => import('@/views/ttlogin/index'), hidden: true },
  { path: '/cslogin', component: () => import('@/views/cslogin/index'), hidden: true },
  { path: '/foodhome', component: () => import('@/views/foodhome/index'), hidden: true },
  { path: '/childhome', component: () => import('@/views/childhome/index'), hidden: true },
  { path: '/qwhome', component: () => import('@/views/qiuwei/index'), hidden: true },
  { path: '/qiuweihome', component: () => import('@/views/qiuwei/index'), hidden: true },
  { path: '/404', component: () => import('@/views/404'), hidden: true },
]

export const asyncRouterMap = [
  {
    path: '',
    component: Layout,
    redirect: '/home',
    name: 'home',
    hidden: true,
    children: [{
      path: 'home',
      name: 'home',
      component: () => import('@/views/home/index'),
      meta: { title: '首页', icon: 'home' }
    }]
  },
  {
    path: '/ttvipBrand',
    component: Layout,
    redirect: '/ttvipBrand/vipBrandHome',
    name: 'ttvipBrand',
    meta: { title: '品牌首页', icon: 'ic_brand_general' },
    hidden: false,
    children: [{
      path: 'vipBrandHome',
      name: 'vipBrandHome',
      component: () => import('@/views/ttvipBrand/vipBrandHome/index'),
      meta: { title: '品牌首页', icon: 'ic_brand_general'}
    }]
  },
  {
    path: '/ttGoodsAnalyse',
    component: Layout,
    redirect: '/ttGoodsAnalyse/index',
    name: 'ttGoodsAnalyse',
    meta: { title: '商品分析', icon: 'ic_nav_shop_sel' },
    hidden: false,
    children: [{
      path: 'goodsAnalyse',
      name: 'goodsAnalyse',
      component: () => import('@/views/ttGoodsAnalyse/index'),
      meta: { title: '商品分析', icon: 'ic_nav_shop_sel'}
    }]
  },
  {
    path: '/ttbrandAccount',
    component: Layout,
    redirect: '/ttbrandAccount/vipOrder',
    name: 'ttbrandAccount',
    meta: { title: '账户管理', icon: 'ic_nav_account' },
    hidden: false,
    children: [{
      path: 'vipOrder',
      name: 'vipOrder',
      component: () => import('@/views/ttbrandAccount/vipOrder/index'),
      meta: { title: '我的订单', icon: 'ic_nav_account'},
    },{
      path: 'confirmOrder',
      name: 'confirmOrder',
      component: () => import('@/views/ttbrandAccount/confirmOrder/index'),
      meta: { title: '支付确认'},
      hidden: true,
    }]
  },
  { path: '*', 
    redirect: '/404', 
    hidden: true 
  }
]

export default new Router({
  // mode: 'history', //后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

