<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
	name: 'App',
}
</script>


<style lang="less">
  @import '/src/base.less';

  body {
    background: @base-app-container-background;
  }

  .navbar .title-label {
    color: @base-app-title-color;
  }

  .app-liveSchedule-backgroundPrintPopover{
    min-width: 68px;
    min-height: 39px;
    color:#606266;
  }


  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb  {
    background-color: @base-app-span-color;
    border-radius: 6px;
  }


.el-divider {
  background: #F2F2F5;
}

.el-table__header-wrapper .cell {
  color: @base-app-title-color;
}
.el-table__fixed-header-wrapper .cell {
  color: @base-app-title-color;
}

.el-table__fixed-body-wrapper .el-table__cell {
  background: @base-app-container-background;
}

.el-date-editor {
  background: @base-app-container-background;
}

.el-checkbox .el-checkbox__inner {
  background: @base-app-container-background;
}

.el-date-range-picker__content.is-left {
    border-right: 1px solid @base-app-border-color;
}

.el-popover {
  color: @base-app-title-color;
}

.el-dialog {
  border-radius: 12px;
}

.el-loading-mask {
  background-color: @base-app-el-loading-mask-bg;
}

.el-tooltip__popper.is-light {
  border-color: #FFF;
}

</style>
