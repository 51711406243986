import Cookies from 'js-cookie'
const SupportKey = 'supportKey'
export function getSupport() {
	return Cookies.get(SupportKey)
}

export function setSupport(isSupport) {
	return Cookies.set(SupportKey, isSupport, { expires: 3 })
}

export function setCookie(key, value, expires) {
	return Cookies.set(key, value, { expires: expires })
}

export function getCookie(key) {
	return Cookies.get(key)
}

/**
 * 移除订单支付 cookie 信息
 */
export function removeOrderPayInfo() {
	Cookies.remove('pay_goodId')
	Cookies.remove('pay_outerTradeNo')
}

