import request from '@/utils/request'

export function login(username, password) {
	return request({
		url: '/auth?member.login',
		method: 'post',
		data: {
			userName: username,
			password,
			rememberMe: false,
		},
	})
}

export function telLogin(data) {
	return request({
		url: '/auth?member.login.codeverify',
		method: 'post',
		data,
	})
}

export function sendSmsCode(data) {
	return request({
		url: '/auth?member.sms.send',
		method: 'post',
		data
	})
}

export function verifySmsCode(data) {
	return request({
		url: '/auth?member.sms.verify',
		method: 'post',
		data
	})
}

export function ttAuthGet(data) {
	return request({
		url: '/auth?tiktok.auth.get',
		method: 'post',
		data: data,
	})
}

export function ttLogin(data) {
	return request({
		url: '/auth?tiktok.auth.confirm',
		method: 'post',
		data: data,
	})
}

export function getInfo() {
	return request({
		url: '/admin/info',
		method: 'get',
	})
}

export function logout() {
	return request({
		url: '/admin/logout',
		method: 'post',
	})
}

export function fetchList(params) {
	return request({
		url: '/admin/list',
		method: 'get',
		params: params,
	})
}

export function createAdmin(data) {
	return request({
		url: '/admin/register',
		method: 'post',
		data: data,
	})
}

export function updateAdmin(id, data) {
	return request({
		url: '/admin/update/' + id,
		method: 'post',
		data: data,
	})
}

export function updateStatus(id, params) {
	return request({
		url: '/admin/updateStatus/' + id,
		method: 'post',
		params: params,
	})
}

export function deleteAdmin(id) {
	return request({
		url: '/admin/delete/' + id,
		method: 'post',
	})
}

export function getRoleByAdmin(id) {
	return request({
		url: '/admin/role/' + id,
		method: 'get',
	})
}

export function allocRole(data) {
	return request({
		url: '/admin/role/update',
		method: 'post',
		data: data,
	})
}
