<template>
  <div>
    <img class="qiuwei-logo" src="https://beiimg-1257667045.cos.ap-shanghai.myqcloud.com/upload/trade/2207/18/15293817690000_432x96.png" alt="" />
    <div class="avatar-container">
      <div style="display:flex;align-items: center;height: 100%;">
        <el-popover trigger="hover">
          <div>
            <img class="qrcode" src="https://beiimg-1257667045.cos.ap-shanghai.myqcloud.com/upload/trade/2207/06/98531909630000_1125x1125.jpeg" alt="" />
          </div>
          <div slot="reference" class="customer-service">
            <svg-icon icon-class="ic_service"></svg-icon>
            <label class="customer-label">联系客服</label>
          </div>
        </el-popover>

        <div class="avatar-wrapper">
          <img class="user-avatar" src="https://h0.beicdn.com/open202136/186a1bba7ad43ccb_512x512.jpg" />
          <label class="user-name">{{ brandName }}</label>
          <img class="level-icon" :src="levelIcon" alt="">
          <label class="logout-label" @click="logout">退出</label>
        </div>
      </div>
    </div>
    <el-divider class="header-divider"></el-divider>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'NavHeader',
  data() {
    return {
      levelIcon: ''
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'name', 'brandName']),
  },
  created() {
    this.fetchUserInfo()
  },
  methods: {
    logout() {
      this.$confirm('确定退出？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.reload() // 为了重新实例化vue-router对象 避免bug
        })
      }).catch(() => {

      });
    },
    fetchUserInfo() {
      this.$store
        .dispatch('GetInfo')
        .then(res => {
          this.levelIcon = res.data.gradeIcon
        })
    },
  }
}
</script>


<style lang="less" scoped>
.qiuwei-logo {
	width: 216px;
	height: 48px;
	position: fixed;
	z-index: 1004;
	top: 16px;
	margin-left: 16px;
}

.qrcode {
	width: 300px;
	height: 300px;
}

.avatar-container {
	height: 80px;
	display: inline-block;
	position: absolute;
	right: 32px;
	// position: fixed;
	// margin-left: 915px;

	.customer-service {
		margin: 20px 35px 20px 20px;
		display: flex;
		align-items: center;
		.customer-label {
			margin-left: 8px;
			font-family: PingFangSC-Regular;
			font-size: 14px;
			color: #646474;
			line-height: 20px;
		}
	}

	.avatar-wrapper {
		position: relative;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.user-avatar {
			width: 24px;
			height: 24px;
			border-radius: 12px;
		}

		.level-icon {
			width: 40px;
			height: 16px;
			margin-right: 32px;
		}

		.logout-label {
			font-family: PingFangSC-Regular;
			font-size: 14px;
			color: #909099;
			text-align: right;
			line-height: 20px;
		}

		.user-name {
			padding: 0 3px 0 8px;
			font-family: PingFangSC-Regular;
			font-size: 14px;
			color: #646474;
			text-align: right;
			line-height: 20px;
		}

		.el-icon-caret-bottom {
			position: absolute;
			right: -15px;
			font-size: 12px;
		}
	}
}

.el-divider--horizontal {
	margin: 0;
}

.header-divider {
	position: fixed;
	top: 80px;
	width: 100%;
}

@media screen and (min-width: 1490px) {
	.header-divider {
		width: 1440px;
	}
}

@media screen and (max-width: 1490px) {
	.header-divider {
		width: 1400px;
	}
}
</style>

