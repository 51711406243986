<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
export default {
	name: 'SvgIcon',
	props: {
		iconClass: {
			type: String,
			required: true,
		},
		className: {
			type: String,
		},
	},
	computed: {
		iconName() {
			return `#icon-${this.iconClass}`
		},
		svgClass() {
			if (this.className) {
				return 'svg-icon ' + this.className
			} else {
				return 'svg-icon'
			}
		},
	},
}
</script>

<style scoped>
.svg-icon {
	width: 1.2em;
	height: 1.2em;

	fill: currentColor;
	overflow: hidden;
}
</style>
