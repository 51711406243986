import 'element-ui/lib/theme-chalk/index.css'
import Vue from 'vue'
import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import '/src/assets/elUiTheme/business/index.css'

import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import VCharts from 'v-charts'

import '@/styles/index.scss' // global css

import App from './App'
import router from './router'
import store from './store'

import '@/icons' // icon
import '@/permission' // permission control

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import less from 'less'
import echarts from 'echarts'
import html2canvas from 'html2canvas'

import VueDOMPurifyHTML from 'vue-dompurify-html'
Vue.use(VueDOMPurifyHTML)

import china from 'echarts/map/json/china.json'
echarts.registerMap('china', china)

Vue.prototype.$echarts = echarts
Vue.prototype.$html2canvas = html2canvas

Vue.use(ElementUI, { locale })
Vue.use(VCharts)
Vue.use(VueQuillEditor)
Vue.use(less)

// 水印
import watermark from './utils/watermark.js'
Vue.prototype.$watermark = watermark


import VueLoadmore from 'vuejs-loadmore'
Vue.use(VueLoadmore)

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

Vue.config.productionTip = false

new Vue({
	el: '#app',
	router,
	store,
	components: { App },
	template: '<App/>',
})
