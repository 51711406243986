<template>
  <div class="menu-wrapper">
    <!-- <template v-for="item in routes" v-if="!item.hidden && item.children"> -->
    <template v-for="item in getValidItems">
      <router-link
        v-if="
          hasOneShowingChildren(item.children) &&
            !item.children[0].children &&
            !item.alwaysShow
        "
        :key="item.children[0].name"
        :to="item.path + '/' + item.children[0].path"
      >
        <el-menu-item
          :index="item.path + '/' + item.children[0].path"
          :class="{ 'submenu-title-noDropdown': !isNest }"
        >
          <svg-icon 
            v-if="
              item.children[0].meta && item.children[0].meta.icon
            "
            :icon-class="item.children[0].meta.icon"
          ></svg-icon>
          <span
            v-if="
              item.children[0].meta && item.children[0].meta.title
            "
            slot="title"
          >
            {{ item.children[0].meta.title }}
          </span>
        </el-menu-item>
      </router-link> 

      <el-submenu v-else :key="item.name" :index="item.name || item.path">
        <template slot="title">
          <svg-icon
            v-if="item.meta && item.meta.icon"
            :icon-class="item.meta.icon"
          ></svg-icon>
          <span v-if="item.meta && item.meta.title" slot="title">
            {{ item.meta.title }}
          </span>
        </template>

        <template v-for="child in getCouldShownItems(item)">
          <sidebar-item
            v-if="child.children && child.children.length > 0"
            :key="child.path"
            :is-nest="true"
            class="nest-menu"
            :routes="[child]"
          ></sidebar-item>

          <router-link
            v-else
            :key="child.name"
            :to="item.path + '/' + child.path"
          >
            <el-menu-item :index="item.path + '/' + child.path" :data-title="child.meta.title">
              <svg-icon
                v-if="child.meta && child.meta.icon"
                :icon-class="child.meta.icon"
              ></svg-icon>
              <span
                v-if="child.meta && child.meta.title"
                slot="title"
              >
                {{ child.meta.title }}
              </span>
            </el-menu-item>
          </router-link>
        </template>
      </el-submenu>
    </template>
  </div>
</template>

<script>
export default {
	name: 'SidebarItem',
	props: {
		routes: {
			type: Array,
		},
		isNest: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		getValidItems() {
			return this.routes.filter(item => {
				return !item.hidden && item.children
			})
		},
	},

	methods: {
		hasOneShowingChildren(children) {
			const showingChildren = children.filter(item => {
				return !item.hidden
			})
			if (showingChildren.length === 1) {
				return true
			}
			return false
		},

		getCouldShownItems(item) {
			return item.children.filter(child => {
				return !child.hidden
			})
		},
	},
}
</script>

<style>
#app .sidebar-container .el-submenu .is-active {
	min-width: 180px !important;
	/* background-color: #1890ff !important; */
}

.el-submenu.is-active .el-submenu__title {
	color: #2650F5 !important;
}

.el-submenu__title {
  background-color: white !important;
  padding-left: 24px !important;
}

#app .sidebar-container .svg-icon {
  filter: drop-shadow(#2E2F33 100px 0);
  transform: translateX(-100px);
  margin-right: 4px;
} 

#app .sidebar-container .is-active .svg-icon {
  filter: drop-shadow(#3B54F5 100px 0);
  transform: translateX(-100px);
}
</style>

<style scoped>
.el-submenu /deep/ .el-menu-item {
	height: 29px;
	line-height: 29px;
	padding-left: 61px !important;
	color: white;
}

.el-menu-item {
  padding-left: 24px !important;
}

/deep/ .el-menu-item {
  height: 40px;
  line-height: 40px;
}

/deep/ .el-submenu__title {
  height: 40px;
  line-height: 40px;
}

/deep/ .el-submenu .el-menu-item {
  font-size: 12px;
  color: #646474 !important;
}

/deep/ .el-submenu .el-menu-item.is-active {
  color: #2650F5 !important;
}

.sidebar-container .is-active.is-opened .router-link-exact-active.router-link-active span:before {
  width: 3px;
  height: 8px;
  background: #2650F5;
}

.router-link-exact-active {
	display: inline-block;
	text-decoration: none;
}



</style>
